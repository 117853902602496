import { Link, useSearchParams } from 'react-router-dom'
import { serviceActions } from './store/service';
import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';


function RightPanel(props) {

    let buttonName;
    if (props.rightProps.nextUrl === undefined) {
        buttonName = "예약 완료";
    } else {
        buttonName = "다음";
    }

    const serviceList = useSelector((state) => state.service);
    const dateList = useSelector((state) => state.date);
    const [searchParams, setSearchParams] = useSearchParams();

    

    const serviceList2= [];
    searchParams.forEach((value, key) => {
        serviceList2.push(key + " " + value);
        
    });



    
    


    return (
        <>
            {/* <div className="hidden md:block w-96 h-screen overflow-y-none bg-white border border-gray-200 rounded-lg shadow sm:p-6"> */}
                <h5 className="mb-3 text-base font-semibold text-gray-900 md:text-xl text-center">
                Services
                </h5>
                {dateList.length > 1 && (
                    <div className="flex flex-row space-x-4 my-5">
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5" />
                        </svg>
                    </div>
                    <div className="text-sm text-gray-500 truncate">{dateList.split(' ')[0]}</div>
                    
                </div>
                )}
                

                {dateList.length > 1 && (
                    <div className="flex flex-row space-x-4 my-5">
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>
                        </div>
                        <div className="text-sm text-gray-500 truncate">{dateList.split(' ')[1]}</div>
                        
                    </div>
                )}
                

                <div className="h-60">
                    <ul role="list" className="divide-y divide-gray-200">
                        {/* {serviceList?.map(service => (
                            <li key={service.id} className="py-3 sm:py-4">
                            <div className="flex items-center">
                                <div className="flex-1">
                                    <p className="text-sm font-medium text-gray-900 truncate d">{service.name}</p>
                                    <p className="text-sm text-gray-500 truncate ">30m</p>
                                </div>
                            </div>
                        </li>
                        ))} */}
                        {serviceList?.map(service => (
                            <li key={service.id} className="py-3 sm:py-4">
                            <div className="flex items-center">
                                <div className="flex-1">
                                    <p className="text-sm font-medium text-gray-900 truncate d">{service.name}</p>
                                    <p className="text-sm text-gray-500 truncate ">30m</p>
                                </div>
                            </div>
                        </li>
                        ))}
                        
                    </ul>
                </div>
                
                

                {/* <div className="relative ">
                    {props.rightProps.ready && (
                        <div className=" hidden md:block absolute left-2 top-0 right-0">
                        <Link disabled to={props.rightProps.nextUrl} className="flex w-80 justify-center rounded-md bg-indigo-600  px-3 py-3 text-lg font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">{buttonName}</Link>
                    </div>
                    )}
                    
                </div> */}
                
                
                
            {/* </div> */}
        </>
    )
}

export default RightPanel;