import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import Schedule from "./Schedule";
import Review from "./Review";
import Services from "./Services";
import CarInfo from "./CarInfo";
import Contact from "./Contact";
import Error from "./Error";
import UserSearch from "./UserSearch";
import CarSelection from "./CarSelection";
import AdminMain from "./admin/AdminMain";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import { Provider } from "react-redux";
import { createStore } from "redux";
import store from "./store/service";
import Complete from "./Complete";

const router = createBrowserRouter([
  { path: "/", element: <App /> },
  { path: "/schedule", element: <Schedule /> },
  { path: "/review", element: <Review /> },
  { path: "/services", element: <Services /> },
  { path: "/carinfo", element: <CarInfo /> },
  { path: "/contact", element: <Contact /> },
  { path: "/complete", element: <Complete /> },
  { path: "/error", element: <Error /> },
  { path: "/usersearch", element: <UserSearch /> },
  { path: "/carselection", element: <CarSelection /> },
  { path: "/admin/main", element: <AdminMain /> },
  { path: "/signin", element: <SignIn /> },
  { path: "/signup", element: <SignUp /> },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <RouterProvider router={router} />
  </Provider>

  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
