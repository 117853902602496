import { Link, useSearchParams, useNavigate } from "react-router-dom";
import RightPanel from "./RightPanel";
import { defaultTheme, Provider } from "@adobe/react-spectrum";
import { Calendar } from "@adobe/react-spectrum";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button, Typography } from "@material-tailwind/react";
import dayjs from 'dayjs';

function CarInfo() {
  //const nextUrl = "/contact";
  const navigate = useNavigate();
  const rightProps = { nextUrl: "/contact", ready: false };
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedMakes, setSelectedMakes] = useState("");
  const [selectedModels, setSelectedModels] = useState("");
  const [selectedYears, setSelectedYears] = useState("");
  const [selectedPlate, setSelectedPlate] = useState("");
  const [selectedMileage, setSelectedMileage] = useState("");
  const [selectedVIN, setSelectedVIN] = useState("");
  const [serviceList, setServiceList] = useState(null);
  const [carList, setCarList] = useState([]);
  const [selectedCar, setSelectedCar] = useState("");
  const [addCar, setAddCar] = useState(false);
  const [makeList, setMakeList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [branch, setBranch] = useState(null);

  const makesHandler = (event) => {
    setSelectedMakes(event.target.value);
    getOEMs(1, event.target.value);
    let carDesc = '';
    for (let i = 0; i < makeList.length; i++) {
      if (makeList[i].id === event.target.value) {
        carDesc = makeList[i].description;
      }
    }

    localStorage.setItem("make", carDesc);
    localStorage.setItem("makeID", event.target.value);
  };

  const yearsHandler = (event) => {
    setSelectedYears(event.target.value);
    localStorage.setItem("year", event.target.value);
  };

  const modelHandler = (event) => {
    setSelectedModels(event.target.value);

    let carDesc = '';
    for (let i = 0; i < modelList.length; i++) {
      if (modelList[i].id === event.target.value) {
        carDesc = modelList[i].description;
      }
    }

    localStorage.setItem("model", carDesc);
    localStorage.setItem("modelID", event.target.value);
  };

  const plateHandler = (event) => {
    setSelectedPlate(event.target.value);
    localStorage.setItem("plate", event.target.value);
  };

  const mileageHandler = (event) => {
    setSelectedMileage(event.target.value);
    localStorage.setItem("mileage", event.target.value);
  };

  const vinHandler = (event) => {
    setSelectedVIN(event.target.value);
    localStorage.setItem("vin", event.target.value);
  };

  const getCarList = async () => {
    //암호화된 car_id 로컬에서 먼저 삭제. 다른 차들이랑 충돌 날 수 있음.
    localStorage.setItem('eid', '');

    setServiceList(JSON.parse(localStorage.getItem("services")));
    try {
      await axios
        .get(process.env.REACT_APP_API_URL + "/api/v1/getCarList", {
          withCredentials: true,
        })
        .then((response) => {
          setCarList(response.data);
        })
        .catch((error) => {
          navigate("/");
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const getAddress = async () => {
    //localStorage.clear();
    try {
      await axios
        .get(process.env.REACT_APP_API_URL + "/api/v1/getAddress", {
          withCredentials: true,
        })
        .then((response) => {
          setBranch(response.data);
        })
        .catch((error) => {
          console.log(error);
          navigate("/");
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getCarList();
    getOEMs(0, null);
    getYears();
    getAddress();
  }, []);

  const getCarString = () => {
    for (let i = 0; i < carList.length; i++) {
      if (carList[i].eid == selectedCar) {
        return carList[i].make + " " + carList[i].model + " " + carList[i].year;
      }
    }
  };

  const carHandler = (event) => {
    setSelectedCar(event.target.value);
    for (let i = 0; i < carList.length; i++) {
      if (carList[i].eid == event.target.value) {
        localStorage.setItem("eid", carList[i].eid);

        setSelectedPlate(carList[i].plate);
        localStorage.setItem("plate", carList[i].plate);

        setSelectedMileage(carList[i].mileage);
        localStorage.setItem("mileage", carList[i].mileage);

        setSelectedVIN(carList[i].vin);
        localStorage.setItem("vin", carList[i].vin);

        setSelectedMakes(carList[i].make);
        localStorage.setItem("make", carList[i].make);
        localStorage.setItem("makeID", carList[i].makeID);

        setSelectedYears(carList[i].year);
        localStorage.setItem("year", carList[i].year);

        setSelectedModels(carList[i].model);
        localStorage.setItem("model", carList[i].model);
        localStorage.setItem("modelID", carList[i].modelID);
      }
    }
  };

  const newCarHandler = () => {
    //자동차를 새로 생성 여부에 따라 Select a model , select a car 보여줄지 결정.

    localStorage.setItem("eid", '');

    setSelectedPlate('');
    localStorage.setItem("plate", '');

    setSelectedMileage('');
    localStorage.setItem("mileage", '');

    setSelectedVIN('');
    localStorage.setItem("vin", '');

    setSelectedMakes('');
    localStorage.setItem("make", '');

    setSelectedYears('');
    localStorage.setItem("year", '');

    setSelectedModels('');
    localStorage.setItem("model", '');

    if (!addCar) {
      setSelectedCar('');
    }
    setAddCar(!addCar);
  }

  const getOEMs = async (level, parentID) => {
    const params = {
      'level': level,
      'parentID': parentID,
    };
    try {
      await axios.get(process.env.REACT_APP_API_URL + "/api/v1/getOEMs", { params: params, withCredentials: true })
        .then(response => {

          if (level == 0) {
            setMakeList(response.data);
          } else {
            setModelList(response.data);
          }

        }).catch(error => {
          console.log(error);
        })
    } catch (e) {
      console.log(e);
    }
  };

  const getYears = () => {
    const years = [];
    for (let i = 1980; i < dayjs().add(5, 'year').year(); i++) {
      years.push(i);
    }

    setYearList(years);
  }

  return (
    <>
      <div>
        <div className="h-10"></div>
        <div className="flex flex-row justify-evenly">
          <div className="w-96">
            <Link
              to={{
                pathname: "/schedule",
                //search: '?service=' + encodeURIComponent(searchParams.get('service')) + '&date=' + encodeURIComponent(searchParams.get('date')) + '&time=' + encodeURIComponent(searchParams.get('time')),
                search:
                  "?service=" + encodeURIComponent(searchParams.get("service")),
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
                />
              </svg>
            </Link>
          </div>
          <div className="w-96"></div>
        </div>

        <div className="flex flex-row justify-evenly">
          <div className="flex flex-col w-96 max-h-full p-6 space-y-2 bg-white">
            {carList.length > 0 && (
              <>
                {!addCar && (
                  <h2 className="text-base mt-5 font-semibold leading-7 text-gray-900 text-xl">
                    Select a car
                  </h2>
                )}


                {!addCar && (
                  <div>
                    <select
                      defaultValue={selectedCar}
                      onChange={carHandler}
                      name="catlist"
                      autoComplete="country-name"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                    >
                      <option value="">Select a Car</option>
                      {carList.map((cars) => (
                        <option key={cars.eid} value={cars.eid}>
                          {cars.make} {cars.model} {cars.year}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

              </>
            )}

            {carList.length > 0 && (
              <div className="h-10">
                {addCar && (
                  <Button variant="text" onClick={newCarHandler} className="text-left" color="teal" size="sm">Remove the car</Button>
                )}

                {!addCar && (
                  <Button variant="text" onClick={newCarHandler} className="text-left" color="teal" size="sm">Add a new car</Button>
                )}
              </div>
            )}


            {(carList.length == 0 || addCar) && (
              <>
                <h2 className="text-base mt-5 font-semibold leading-7 text-gray-900 text-xl">
                  Select a model
                </h2>
                <div>
                  <select
                    id="makes"
                    value={selectedMakes}
                    onChange={makesHandler}
                    name="makes"
                    autoComplete="country-name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  >
                    <option value="">Select a make</option>
                    {makeList?.map((oem, index) => (
                      <option key={oem.description} value={oem.id}>
                        {oem.description}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <select
                    id="models"
                    value={selectedModels}
                    onChange={modelHandler}
                    name="models"
                    autoComplete="country-name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  >
                    <option defaultValue>Select a model</option>
                    {modelList?.map((oem, index) => (
                      <option key={oem.description} value={oem.id}>
                        {oem.description}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <select
                    id="years"
                    value={selectedYears}
                    onChange={yearsHandler}
                    name="years"
                    autoComplete="country-name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  >
                    <option defaultValue>Select a Year</option>
                    {yearList?.map((year, index) => (
                      <option key={year} value={year}>{year}</option>
                    ))}
                  </select>
                </div>

                <div className="h-10"></div>
              </>
            )}

            <h2 className="text-base mt-5 font-semibold leading-7 text-gray-900 text-xl">
              Enter the car detail
            </h2>
            {/* <h2 className="text-base mt-5 font-semibold leading-7 text-gray-900 text-sm">플레이트</h2> */}
            <div className="content-center flex flex-row space-x-4">
              <input
                value={selectedPlate}
                maxLength={10}
                onChange={plateHandler}
                className="shadow appearance-none border rounded w-80 py-1 px-1 trounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                id="plate"
                type="text"
                placeholder="Plate Number (Optional)"
              ></input>
            </div>

            {/* <h2 className="text-base mt-5 font-semibold leading-7 text-gray-900 text-sm">마일리지</h2> */}
            <div className="content-center mt-1 flex flex-row space-x-4 text-sm">
              <input
                value={selectedMileage}
                maxLength={20}
                onChange={mileageHandler}
                className="shadow appearance-none border rounded w-80 py-1 px-1 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                id="mileage"
                type="number"
                placeholder="Mileage (Optional)"
              ></input>
            </div>

            {/* <h2 className="text-base mt-5 font-semibold leading-7 text-gray-900 text-sm">VIN</h2> */}
            <div className="content-center mt-1 flex flex-row space-x-4 text-sm">
              <input
                value={selectedVIN}
                maxLength={17}
                onChange={vinHandler}
                className="shadow appearance-none border rounded w-80 py-1 px-1 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                id="vin"
                type="text"
                placeholder="VIN (Optional)"
              ></input>
            </div>

            <div className="block sm:hidden fixed bottom-10 bg-white">
              <div className="container">
                {selectedMakes && selectedModels && selectedYears ? (
                  <Link to="/contact">
                    <Button
                      variant="gradient"
                      size="lg"
                      className="w-80 px-3 py-3"
                      color="teal"
                    >
                      NEXT
                    </Button>
                  </Link>
                ) : (
                  <Button
                    disabled
                    variant="gradient"
                    size="lg"
                    className="w-80 jpx-3 py-3"
                    color="teal"
                  >
                    NEXT
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className="w-96">
            <div className="hidden md:block mt-10 w-96 max-h-full overflow-y-none bg-white border border-gray-200 rounded-lg shadow sm:p-6">
              <h5 className="mb-3 text-base font-semibold text-gray-900 md:text-xl text-center">
                Services
              </h5>

              <div>No.1 Jimmy Auto</div>
              <div className="text-gray-500 text-sm mb-8">
              {branch?.address} {branch?.postalCode}
              </div>

              {/* YYYY-MM-DD */}
              <div className="flex flex-row space-x-4 my-5">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                    />
                  </svg>
                </div>
                <div className="text-sm text-gray-500 truncate">
                  {localStorage.getItem("bookingtime")?.split(" ")[0]}
                </div>
              </div>

              {/* HH:MM */}
              <div className="flex flex-row space-x-4 my-5">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                </div>
                <div className="text-sm text-gray-500 truncate">
                  {localStorage.getItem("bookingtime")?.split(" ")[1]}
                </div>
              </div>

              {/* CAR */}
              {selectedCar && (
                <>
                  <div className="flex flex-row space-x-4 my-5">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M8.25 18.75a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 0 1-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 0 0-3.213-9.193 2.056 2.056 0 0 0-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 0 0-10.026 0 1.106 1.106 0 0 0-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12"
                        />
                      </svg>
                    </div>
                    <div className="text-sm text-gray-500 truncate">
                      {getCarString()}
                    </div>
                  </div>
                </>
              )}

              {serviceList?.map((itemt, index) => (
                <>
                  <div className="flex flex-row space-x-4 my-5">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M21.75 6.75a4.5 4.5 0 0 1-4.884 4.484c-1.076-.091-2.264.071-2.95.904l-7.152 8.684a2.548 2.548 0 1 1-3.586-3.586l8.684-7.152c.833-.686.995-1.874.904-2.95a4.5 4.5 0 0 1 6.336-4.486l-3.276 3.276a3.004 3.004 0 0 0 2.25 2.25l3.276-3.276c.256.565.398 1.192.398 1.852Z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.867 19.125h.008v.008h-.008v-.008Z"
                        />
                      </svg>
                    </div>
                    <div className="text-sm text-gray-500 truncate">
                      {itemt.name}
                    </div>
                  </div>
                </>
              ))}
              {/* <div className="relative "> */}
              <div className="mt-24  hidden md:block ">
                {selectedMakes && selectedModels && selectedYears ? (
                  <Link to="/contact">
                    <Button
                      variant="gradient"
                      size="lg"
                      className="w-[21rem] px-3 py-3"
                      color="teal"
                    >
                      NEXT
                    </Button>
                  </Link>
                ) : (
                  <Button
                    disabled
                    variant="gradient"
                    size="lg"
                    className="w-[21rem] jpx-3 py-3"
                    color="teal"
                  >
                    NEXT
                  </Button>
                )}
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CarInfo;
