import React, { useState, useEffect, useRef } from "react";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import RightPanel from "./RightPanel";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { serviceActions } from "./store/service";
import { Button, Typography } from "@material-tailwind/react";

function Services() {
  const navigate = useNavigate();
  const [serviceList, setServiceList] = useState(null);
  const [branch, setBranch] = useState(null);
  const [lastServiceChecked, setLastServiceChecked] = useState(false);
  const [lastServiceIndex, setLastServiceIndex] = useState(-1);
  const [selectedMemo, setSelectedMemo] = useState("");
  const [count, setCount] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isEtcServiceSelected, setIsEtcServiceSelected] = useState(false);

  //const nextUrl = "/schedule";
  const [rightProps, setRightProps] = useState({
    nextUrl: "/schedule",
    ready: false,
  });

  const getServices = async () => {
    //localStorage.clear();
    try {
      await axios
        .get(process.env.REACT_APP_API_URL + "/api/v1/serviceList", {
          withCredentials: true,
        })
        .then((response) => {
          setServiceList(response.data);
        })
        .catch((error) => {
          console.log(error);
          navigate("/");
        });
    } catch (e) {
      console.log(e);
    }
  };

  const getAddress = async () => {
    //localStorage.clear();
    try {
      await axios
        .get(process.env.REACT_APP_API_URL + "/api/v1/getAddress", {
          withCredentials: true,
        })
        .then((response) => {
          setBranch(response.data);
        })
        .catch((error) => {
          console.log(error);
          navigate("/");
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getServices();
    getAddress();
  }, []);

  const selectedService = (event) => {
    event.preventDefault();
    serviceList.map((service) => {
      if (service.id == event.target.value) {
      }
    });
  };

  const dispatch = useDispatch();
  const serviceArrayList = useSelector((state) => state.service);

  //서비스 목록을 클릭 했을 때 작동. 기타 버튼은 제외.
  const serviceAddHandler = (service) => {
    //get all checkboxes
    const checkboxes = document.getElementsByName("serviceList");
    const checkboxLen = checkboxes.length;
    const params = new URLSearchParams(searchParams);

    //마지막 기타가 선택되어 있다면 버튼 비활성화 시킨다.
    if (lastServiceChecked) {
      checkboxes[checkboxLen - 1].checked = false;
      dispatch(serviceActions.removeService(serviceArrayList.length - 1));
      params.delete("etc");
      setSearchParams(params.toString());
      setLastServiceChecked(false);
    }

    //동일한 버튼을 클릭 했을경우 인덱스 번호 획득
    let existsIndex = -1;
    for (let i = 0; i < serviceArrayList.length; i++) {
      if (serviceArrayList[i].id == service.id) {
        existsIndex = i;
      }
    }

    //동일한 서비스 목록이 state에 있었다면 제거하고, 아니면 새로 입력.
    if (existsIndex > -1) {
      dispatch(serviceActions.removeService(existsIndex));
      params.delete("service" + service.id);
      setSearchParams(params.toString());
      setRightProps({ nextUrl: "/schedule", ready: false });
    } else {
      dispatch(serviceActions.addService(service));
      searchParams.set("service" + service.id, service.id);
      setSearchParams(searchParams);
      setRightProps({ nextUrl: "/schedule", ready: true });
    }
  };

  //기타 버튼을 클릭 했을 때 작동
  const serviceAddHandlerETC = (service) => {
    //모든 쿼리 스트링 삭제.
    const params = new URLSearchParams(searchParams);
    searchParams.forEach((value, key) => {
      params.delete(key);
    });
    setSearchParams(params.toString());

    if (lastServiceChecked) {
      setLastServiceChecked(false);
    } else {
      setLastServiceChecked(true);
    }

    const checkboxes = document.getElementsByName("serviceList");
    const checkboxLen = checkboxes.length;

    //make other checkboxs false
    for (var i = 0; i < checkboxLen - 1; i++) {
      checkboxes[i].checked = false;
    }

    checkboxes[checkboxLen - 1].checked = true; //마지막 기타만 체크.

    dispatch(serviceActions.removeAll());
    dispatch(serviceActions.addService(service));
    searchParams.forEach((value, key) => {
    });
  };

  const [selectedServiceList, setSelectedServiceList] = useState([]);

  const ServiceListHandler = (event) => {
    const obj = JSON.parse(event.target.value);

    const checkboxes = document.getElementsByName("serviceList");
    const checkboxLen = checkboxes.length;

    setIsEtcServiceSelected(false);

    if (obj.type == "D") {
      setIsEtcServiceSelected(true);
      setSelectedMemo('');
      //make other checkboxs false
      for (var i = 0; i < checkboxLen - 1; i++) {
        checkboxes[i].checked = false;
      }

      checkboxes[checkboxLen - 1].checked = true; //마지막 기타만 체크.
      setLastServiceIndex(obj.id);

      const list = [];
      list.push(obj);
      setSelectedServiceList([...list]);
      localStorage.setItem("services", JSON.stringify([...list]));
    } else {
      checkboxes[checkboxLen - 1].checked = false; //마지막 기타만 체크 해제

      
      const list = selectedServiceList;
      let isSliced = false;
      for (let i = 0; i < list.length; i++) {
        if (list[i].type == "D") {
          list.splice(i, 1);
        } else if (list[i].id === obj.id) {
          list.splice(i, 1);
          isSliced = true;

          let serviceValue = searchParams.get("service");
          let newValue = "";
          if (lastServiceIndex > -1) {
            setLastServiceIndex(-1);
          }
        }
      }

      if (!isSliced) {
        list.push(obj);
      }

      setSelectedServiceList([...list]);
      localStorage.setItem("services", JSON.stringify([...list]));
      localStorage.removeItem("memo");
    }
  };

  const memoHandler = (event) => {
    setSelectedMemo(event.target.value);
    localStorage.setItem("memo", JSON.stringify(event.target.value));
    setCount(event.target.value.length);
  };

  return (
    <>
      <div>
        <div className="h-10"></div>

        <div className="flex flex-row justify-evenly">
          <div className="w-96">
            <a href="/">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
                />
              </svg>
            </a>
          </div>
          <div className="w-96"></div>
        </div>

        <div className="flex flex-row justify-evenly">
          <div>
            <div className="flex flex-col w-96 max-h-full p-6 space-y-2 bg-white  rounded-lg">
              <h2 className="text-base mt-5 font-semibold leading-7 text-gray-900 text-xl">
                Select services
              </h2>
              <div className="flex flex-col space-y-4 mt-4">
                {serviceList?.map((service) => (
                  <div key={service.id}>
                    <input
                      type="checkbox"
                      id={service.id}
                      value={JSON.stringify(service)}
                      onChange={ServiceListHandler}
                      name="serviceList"
                      className="hidden peer"
                      required=""
                    />
                    <label
                      htmlFor={service.id}
                      className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border-2 border-gray-200 rounded-lg cursor-pointer  peer-checked:border-teal-600 hover:text-gray-600  peer-checked:text-gray-600 hover:bg-gray-50 "
                    >
                      <div className="block">
                        <div className="w-full text-lg font-semibold">
                          {service.name}
                        </div>
                        {/* <div className="w-full text-sm">{service.duration} 소요</div> */}

                        {/* {service.type === "D" ? (
                          <div className="w-full">
                            <div className="text-right text-xs">
                              {count}/250
                            </div>
                            <textarea
                              maxLength="250"
                              value={selectedMemo}
                              onChange={(e) => memoHandler(e)}
                              rows="4"
                              className="text-sm w-72 text-gray-900 bg-gray-50 rounded-lg border border-gray-300"
                              placeholder="please add details"
                            ></textarea>
                          </div>
                        ) : (
                          ""
                        )} */}
                      </div>
                    </label>
                  </div>
                ))}

                {isEtcServiceSelected && (
                  <div className="w-full">
                    <textarea
                      maxLength="250"
                      value={selectedMemo}
                      onChange={(e) => memoHandler(e)}
                      rows="4"
                      className="text-sm w-full text-gray-900 bg-gray-50 rounded-lg border-2 border-teal-600 focus:ring-teal-600 focus:border-teal-500"
                      placeholder="점검이 필요한 경우 점검 비용이 발생할 수 있습니다. (If an inspection is required, there may be associated inspection costs.)"
                    ></textarea>
                    <div className="text-right text-xs">{count}/250</div>
                  </div>
                )}


                <div className="sm:hidden">
                  {selectedServiceList.length > 0 ? (
                    <Link to="/schedule">
                      <Button
                        variant="gradient"
                        size="lg"
                        className="w-[21rem]"
                        color="teal"
                      >
                        NEXT
                      </Button>
                    </Link>
                  ) : (
                    <Button
                      disabled
                      variant="gradient"
                      size="lg"
                      className="w-[21rem]"
                      color="teal"
                    >
                      NEXT
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="w-96">
            <div className="hidden md:block mt-10 w-96 max-h-full overflow-y-none bg-white border border-gray-200 rounded-lg shadow sm:p-6">
              <h5 className="mb-3 text-base font-semibold text-gray-900 md:text-xl text-center">
                Services
              </h5>

              <div className="h-60">
                <div>No.1 Jimmy Auto</div>
                <div className="text-gray-500 text-sm mb-8">
                  {branch?.address} {branch?.postalCode}
                </div>
                {selectedServiceList?.map((itemt, index) => (
                  <>
                    <div className="flex flex-row space-x-4 my-5">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-5 h-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21.75 6.75a4.5 4.5 0 0 1-4.884 4.484c-1.076-.091-2.264.071-2.95.904l-7.152 8.684a2.548 2.548 0 1 1-3.586-3.586l8.684-7.152c.833-.686.995-1.874.904-2.95a4.5 4.5 0 0 1 6.336-4.486l-3.276 3.276a3.004 3.004 0 0 0 2.25 2.25l3.276-3.276c.256.565.398 1.192.398 1.852Z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M4.867 19.125h.008v.008h-.008v-.008Z"
                          />
                        </svg>
                      </div>
                      <div className="text-sm text-gray-500 truncate">
                        {itemt.name}
                      </div>
                    </div>
                  </>
                ))}
              </div>

              {/* <div className="relative "> */}
              <div className="mt-24 hidden md:block items-center">
                {selectedServiceList.length > 0 ? (
                  <Link to="/schedule">
                    <Button
                      variant="gradient"
                      size="lg"
                      className="w-[21rem]"
                      color="teal"
                    >
                      NEXT
                    </Button>
                  </Link>
                ) : (
                  <Button
                    disabled
                    variant="gradient"
                    size="lg"
                    className="w-[21rem]"
                    color="teal"
                  >
                    NEXT
                  </Button>
                )}
              </div>
              {/*      */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Services;
