import "./App.css";
import { useState } from "react";
import { Card, Input, Button, Typography } from "@material-tailwind/react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

function SignUp() {
  const navigate = useNavigate();

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [progress, setProgress] = useState(false);

  const googleLogin = () => {
    window.location.href =
      process.env.REACT_APP_API_URL + "/oauth2/authorization/google";
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setProgress(true);

    setEmailError("");
    setPasswordError("");

    const formData = new FormData(e.target);
    const body = JSON.stringify({
      email: formData.get("email"),
      password: formData.get("password"),
    });

    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    await axios
      .post(process.env.REACT_APP_API_URL + "/join", body, config)
      .then((response) => {
        const expires = new Date();
        expires.setTime(expires.getTime() + 20 * 60 * 1000);
        const authorization = response.headers
          .get("Authorization")
          .split(" ")[1];
        document.cookie = `Authorization=${authorization};expires=${expires.toUTCString()};path=/`;
        navigate("/services");
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.data.fieldErrors) {
            error.response.data.fieldErrors.forEach((fieldError) => {
              if (fieldError.field === "email") {
                setEmailError(fieldError.message);
              }

              if (fieldError.field === "password") {
                setPasswordError(fieldError.message);
              }
            });
          }

          if (error.response.data.message) {
            setEmailError(error.response.data.message);
          }
        } else if (error.request) {
        } else {
        }
      });
      setProgress(false);
  };

  return (
    <>
      <div className="flex justify-center items-center">
        <div className="max-w-xl mt-10 mx-5 my-1 block rounded-lg dark:bg-white">
          <div className="text-left">
            <Card color="transparent" shadow={false}>
              <form
                className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96"
                method="POST"
                onSubmit={onSubmit}
              >
                <div className="mb-1 flex flex-col gap-2">
                  <Typography variant="h2" color="blue-gray" className="mb-10">
                    No1. Jimmy Auto
                  </Typography>

                  <Typography variant="h6" color="blue-gray">
                    Sign up with:
                  </Typography>
                  <Button
                    onClick={googleLogin}
                    variant="outlined"
                    color="blue-gray"
                    className="flex items-center gap-3"
                    fullWidth
                  >
                    sign Up
                    {/* <img src="https://docs.material-tailwind.com/icons/google.svg" alt="metamask" className="h-6 w-6" /> */}
                    <svg
                      width="17"
                      className="h-5 w-5"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_1156_824)">
                        <path
                          d="M16.3442 8.18429C16.3442 7.64047 16.3001 7.09371 16.206 6.55872H8.66016V9.63937H12.9813C12.802 10.6329 12.2258 11.5119 11.3822 12.0704V14.0693H13.9602C15.4741 12.6759 16.3442 10.6182 16.3442 8.18429Z"
                          fill="#4285F4"
                        ></path>
                        <path
                          d="M8.65974 16.0006C10.8174 16.0006 12.637 15.2922 13.9627 14.0693L11.3847 12.0704C10.6675 12.5584 9.7415 12.8347 8.66268 12.8347C6.5756 12.8347 4.80598 11.4266 4.17104 9.53357H1.51074V11.5942C2.86882 14.2956 5.63494 16.0006 8.65974 16.0006Z"
                          fill="#34A853"
                        ></path>
                        <path
                          d="M4.16852 9.53356C3.83341 8.53999 3.83341 7.46411 4.16852 6.47054V4.40991H1.51116C0.376489 6.67043 0.376489 9.33367 1.51116 11.5942L4.16852 9.53356Z"
                          fill="#FBBC04"
                        ></path>
                        <path
                          d="M8.65974 3.16644C9.80029 3.1488 10.9026 3.57798 11.7286 4.36578L14.0127 2.08174C12.5664 0.72367 10.6469 -0.0229773 8.65974 0.000539111C5.63494 0.000539111 2.86882 1.70548 1.51074 4.40987L4.1681 6.4705C4.8001 4.57449 6.57266 3.16644 8.65974 3.16644Z"
                          fill="#EA4335"
                        ></path>
                      </g>
                      <defs>
                        <clipPath id="clip0_1156_824">
                          <rect
                            width="16"
                            height="16"
                            fill="white"
                            transform="translate(0.5)"
                          ></rect>
                        </clipPath>
                      </defs>
                    </svg>
                    Continue with Google
                  </Button>
                  <div className="mt-7 mb-7">
                    <div className="text-center">or</div>
                  </div>

                  <Typography variant="h6" color="blue-gray" className="mb-2">
                    Sign up with your email address:
                  </Typography>

                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="mt-5"
                  >
                    Your Email
                  </Typography>
                  <Input
                    size="lg"
                    name="email"
                    placeholder="name@mail.com"
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                  {emailError ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {emailError}
                    </span>
                  ) : (
                    ""
                  )}
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="mt-5"
                  >
                    Password
                  </Typography>
                  <Input
                    type="password"
                    name="password"
                    size="lg"
                    placeholder="********"
                    className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                  {passwordError ? (
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {passwordError}
                    </span>
                  ) : (
                    ""
                  )}
                </div>

                {progress === false ? (
                  <Button type="submit" className="mt-6" fullWidth color="teal">
                    sign Up
                  </Button>
                ) : (
                  <Button type="submit" className="mt-6" disabled loading="true" fullWidth color="teal">
                    LOADING
                  </Button>

                )}



                <Typography
                  color="gray"
                  className="mt-4 text-center font-normal"
                >
                  Have an account?{" "}
                  <a href="/signin" className="font-medium text-gray-900">
                    Sign In
                  </a>
                </Typography>
              </form>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUp;
