import Booking from "./Schedule";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import RightPanel from "./RightPanel";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button } from "@material-tailwind/react";

function Review() {
  const rightProps = { nextUrl: "/review", ready: false };
  const [searchParams, setSearchParams] = useSearchParams();
  const [progress, setProgress] = useState(false);

  const navigate = useNavigate();

  const saveBooking = async () => {
    try {
      localStorage.setItem("saveErrorMessage", "");
      const serviceIDs = [];
      const serviceNames = [];

      const serviceArray = JSON.parse(localStorage.getItem("services"));

      for (let i = 0; i < serviceArray.length; i++) {
        serviceIDs.push(serviceArray[i].id);
        serviceNames.push(serviceArray[i].name);
      }

      const postData = {
        customer: {
          firstName: localStorage.getItem("firstname"),
          lastName: localStorage.getItem("lastname"),
          phone: localStorage.getItem("phone"),
          email: localStorage.getItem("email"),
        },
        car: {
          eid: localStorage.getItem("eid"),
          make: localStorage.getItem("make"),
          makeID: localStorage.getItem("makeID"),
          model: localStorage.getItem("model"),
          modelID: localStorage.getItem("modelID"),
          year: localStorage.getItem("year"),
          vin: localStorage.getItem("vin"),
          mileage: localStorage.getItem("mileage"),
          plate: localStorage.getItem("plate"),
        },
        book: {
          servicenote: localStorage.getItem("servicenote"),
          startBookDate:
            localStorage.getItem("bookingtime").split(" ")[0] +
            "T" +
            localStorage.getItem("bookingtime").split(" ")[1],
          branchID: "1",
          serviceMemo: localStorage.getItem("memo"),
          note: localStorage.getItem("bookMemo"),
          serviceIDs: serviceIDs,
        },
      };

      setProgress(true);
      await new Promise((resolve) => setTimeout(resolve, 1000));

      await axios
        .post(process.env.REACT_APP_API_URL + "/api/v1/save/book", postData, {
          withCredentials: true,
        })
        .then((response) => {
          navigate("/complete");
        })
        .catch((error) => {
          console.log(error);
          localStorage.setItem("saveErrorMessage", error.response.error);
          navigate("/error");
        });
      setProgress(false);
      const expires = new Date();
      // document.cookie = `Authorization=;expires=;path=`; //얘약하고 하면 쿠키 자동 삭제. 다시 예약 시도하지 못하도록 막음.
    } catch (e) {
      console.log(e);
      localStorage.setItem("saveErrorMessage", e);
      navigate("/error");
    }
  };

  return (
    <>
      <div>
        <div className="h-10"></div>
        <div className="flex flex-row justify-evenly">
          <div className="w-96">
            <Link
              to={{
                pathname: "/contact",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
                />
              </svg>
            </Link>
          </div>
          <div className="w-96"></div>
        </div>

        <div className="flex flex-row justify-evenly">
          <div>
            <div className="flex flex-col w-96 h-96 p-6 space-y-5 bg-white ">
              <h2 className="text-base mt-5 font-semibold leading-7 text-gray-900 text-xl">
                Please check the reseavation details.
              </h2>
              <div className=" flex items-center gap-x-3 mx-2 my-10 mt-10">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M8.25 18.75a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 0 1-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 0 0-3.213-9.193 2.056 2.056 0 0 0-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 0 0-10.026 0 1.106 1.106 0 0 0-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12"
                  />
                </svg>

                <div className="text-base leading-6 text-gray-600">
                  {localStorage.getItem("make")} {localStorage.getItem("model")}{" "}
                  {localStorage.getItem("year")}
                </div>
              </div>

              <div className=" flex items-center gap-x-3 mx-2 my-10">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 9h3.75M15 12h3.75M15 15h3.75M4.5 19.5h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Zm6-10.125a1.875 1.875 0 1 1-3.75 0 1.875 1.875 0 0 1 3.75 0Zm1.294 6.336a6.721 6.721 0 0 1-3.17.789 6.721 6.721 0 0 1-3.168-.789 3.376 3.376 0 0 1 6.338 0Z"
                  />
                </svg>

                <div className="text-base leading-6 text-gray-600">
                  {localStorage.getItem("firstname")}{" "}
                  {localStorage.getItem("lastname")}
                </div>
              </div>

              <div className=" flex items-center gap-x-3 mx-2 my-10">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.5 12a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0Zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 1 0-2.636 6.364M16.5 12V8.25"
                  />
                </svg>

                <div className="text-base leading-6 text-gray-600">
                  {localStorage.getItem("email")}
                </div>
              </div>

              <div className=" flex items-center gap-x-3 mx-2 my-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                  />
                </svg>

                <div className="text-base leading-6 text-gray-600">
                  {localStorage.getItem("phone")}
                </div>
              </div>

              <div className=" flex items-center gap-x-3 mx-2 my-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                  />
                </svg>
                <div className="text-base leading-6 text-gray-600">
                  {localStorage.getItem("bookingtime").split(" ")[0]}{" "}
                  {localStorage.getItem("bookingtime").split(" ")[1]}
                </div>
              </div>

              <div className=" flex items-center gap-x-3 mx-2 my-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384 512"
                  fill="currentColor"
                  className="w-5 h-5"
                >
                  <path d="M24 32C10.7 32 0 42.7 0 56V456c0 13.3 10.7 24 24 24H40c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24H24zm88 0c-8.8 0-16 7.2-16 16V464c0 8.8 7.2 16 16 16s16-7.2 16-16V48c0-8.8-7.2-16-16-16zm72 0c-13.3 0-24 10.7-24 24V456c0 13.3 10.7 24 24 24h16c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24H184zm96 0c-13.3 0-24 10.7-24 24V456c0 13.3 10.7 24 24 24h16c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24H280zM448 56V456c0 13.3 10.7 24 24 24h16c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24H472c-13.3 0-24 10.7-24 24zm-64-8V464c0 8.8 7.2 16 16 16s16-7.2 16-16V48c0-8.8-7.2-16-16-16s-16 7.2-16 16z" />
                </svg>
                <div className="text-base leading-6 text-gray-600">
                  {localStorage.getItem("vin")}
                </div>
              </div>

              <div className=" flex items-center gap-x-3 mx-2 my-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.5 6v.75m0 3v.75m0 3v.75m0 3V18m-9-5.25h5.25M7.5 15h3M3.375 5.25c-.621 0-1.125.504-1.125 1.125v3.026a2.999 2.999 0 0 1 0 5.198v3.026c0 .621.504 1.125 1.125 1.125h17.25c.621 0 1.125-.504 1.125-1.125v-3.026a2.999 2.999 0 0 1 0-5.198V6.375c0-.621-.504-1.125-1.125-1.125H3.375Z"
                  />
                </svg>
                <div className="text-sm leading-6 text-gray-600">
                  {localStorage.getItem("plate")}
                </div>
              </div>

              <div className=" flex items-center gap-x-3 mx-2 my-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M11.42 15.17 17.25 21A2.652 2.652 0 0 0 21 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 1 1-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 0 0 4.486-6.336l-3.276 3.277a3.004 3.004 0 0 1-2.25-2.25l3.276-3.276a4.5 4.5 0 0 0-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437 1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008Z"
                  />
                </svg>

                <div className="text-base leading-6 text-gray-600">
                  <ul>
                    {JSON.parse(localStorage.getItem("services")).map(
                      (service, index) => (
                        <li key={index}>{service.name}</li>
                      )
                    )}
                  </ul>
                </div>
              </div>
              <div className=" flex items-center  mt-6 mx-2 my-2">
                {progress === false ? (
                  <Button
                    onClick={saveBooking}
                    variant="gradient"
                    size="lg"
                    className="w-[21rem] px-3 py-3"
                    color="teal"
                  >
                    COMPLETE
                  </Button>
                ) : (
                  <Button
                    onClick={saveBooking}
                    variant="gradient"
                    size="lg"
                    className="w-[21rem] px-3 py-3"
                    color="teal"
                    loading="true"
                    disabled
                  >
                    LOADING
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Review;
