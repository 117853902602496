
import { configureStore, createSlice } from '@reduxjs/toolkit'



//services state for service list
const initialState = [];

const serviceSlice = createSlice({
    name: 'service',
    initialState: initialState,
    reducers: {
        addService(state, action) {
            state.push(action.payload)
        },
        removeService(state, action) {
            state.splice(action.payload, 1)
        },
        removeAll(state, action) {
            while (state.length > 0) {
                state.pop();
            }
        }
    }
});

//date and time state for the Right panel
const initialDateState = '';

const dateSlice = createSlice({
    name: 'date',
    initialState: initialDateState,
    reducers: {
        add(state, action) {
            state = action.payload;
            return state;
        },
        del(state) {
            state = '';
        }

    },
})

const initialCustomerInfo = {
    makes:'', years: '', models: '', plate: '', mileage: '', vin: '', 
    firstname: '', lastname:'', email: '', phonenumber: '', memo: ''
};

const customerSlice = createSlice({
    name: 'customer',
    initialState: initialCustomerInfo,
    reducers: {
        add(state, action) {
            state = action.payload;
            return state;
        },
        del(state) {
            state = '';
        }

    },
})

const store = configureStore({
    reducer: { service : serviceSlice.reducer, date: dateSlice.reducer},
});


export const dateActions = dateSlice.actions;
export const serviceActions = serviceSlice.actions;
export default store;