import { Link, useSearchParams, useNavigate } from "react-router-dom";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { Button } from "@material-tailwind/react";

function Contact() {
  //const nextUrl = "/review";
  const navigate = useNavigate();
  const rightProps = { nextUrl: "/review", ready: false };
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedFirstName, setSelectedFirstName] = useState("");
  const [selectedLastName, setSelectedLastName] = useState("");
  const [selectedEmail, setSelectedEmail] = useState("");
  const [selectedPhone, setSelectedPhone] = useState("");
  const [selectedMemo, setSelectedMemo] = useState("");
  const [serviceList, setServiceList] = useState(null);
  const [count, setCount] = useState(0);
  const [customer, setCustomer] = useState([]);
  const [branch, setBranch] = useState(null);

  const getCustomer = async () => {
    setServiceList(JSON.parse(localStorage.getItem("services")));
    try {
      await axios
        .get(process.env.REACT_APP_API_URL + "/api/v1/getCustomer", {
          withCredentials: true,
        })
        .then((response) => {
          setCustomer(response.data);

          setSelectedFirstName(response.data.firstName);
          localStorage.setItem("firstname", response.data.firstName);

          setSelectedLastName(response.data.lastName);
          localStorage.setItem("lastname", response.data.lastName);

          setSelectedEmail(response.data.email);
          localStorage.setItem("email", response.data.email);

          setSelectedPhone(response.data.phone);
          localStorage.setItem("phone", response.data.phone);
        })
        .catch((error) => {
          navigate("/");
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const getAddress = async () => {
    //localStorage.clear();
    try {
      await axios
        .get(process.env.REACT_APP_API_URL + "/api/v1/getAddress", {
          withCredentials: true,
        })
        .then((response) => {
          setBranch(response.data);
        })
        .catch((error) => {
          console.log(error);
          navigate("/");
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getCustomer();
    getAddress();
  }, []);

  const firstNameHandler = (event) => {
    setSelectedFirstName(event.target.value);
    localStorage.setItem("firstname", event.target.value);
  };

  const lastNameHandler = (event) => {
    setSelectedLastName(event.target.value);
    localStorage.setItem("lastname", event.target.value);
  };

  const emailHandler = (event) => {
    setSelectedEmail(event.target.value);
    localStorage.setItem("email", event.target.value);
  };

  const phoneHandler = (event) => {
    setSelectedPhone(event.target.value.replace(/\D/g, ''));
    localStorage.setItem("phone", event.target.value.replace(/\D/g, ''));
  };

  const memoHandler = (event) => {
    setSelectedMemo(event.target.value);
    localStorage.setItem("bookMemo", event.target.value);
    setCount(event.target.value.length);
  };

  return (
    <>
      <div>
        <div className="h-10"></div>
        <div className="flex flex-row justify-evenly">
          <div className="w-96">
            <Link
              to={{
                pathname: "/carinfo",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
                />
              </svg>
            </Link>
          </div>
          <div className="w-96"></div>
        </div>

        <div className="flex flex-row justify-evenly">
          <div>
            <div className="flex flex-col w-96 max-h-full p-6 space-y-2 bg-white  rounded-lg">
              <h2 className="text-base mt-5 font-semibold leading-7 text-gray-900 text-sm">
                Enter contact information
              </h2>
              <div className="content-center mt-1 flex flex-row space-x-4 text-sm">
                <input
                  value={selectedFirstName}
                  maxLength={20}
                  onChange={firstNameHandler}
                  className="shadow appearance-none border rounded w-full py-1 px-1 py-1 trounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-teal-600 focus:border-teal-500 sm:max-w-xs sm:text-sm sm:leading-6"
                  id="username"
                  type="text"
                  placeholder="First Name"
                ></input>
              </div>

              <div className="content-center mt-3 flex flex-row space-x-4 text-sm">
                <input
                  value={selectedLastName}
                  maxLength={20}
                  onChange={lastNameHandler}
                  className="shadow appearance-none border rounded w-full py-1 px-1 trounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-teal-600 focus:border-teal-500 sm:max-w-xs sm:text-sm sm:leading-6"
                  id="username"
                  type="text"
                  placeholder="Last Name"
                ></input>
              </div>

              {/* <div className="content-center mt-3 flex flex-row space-x-4 text-sm">
                          <input value={selectedEmail} maxLength={50} onChange={emailHandler} className="shadow appearance-none border rounded w-full py-1 px-1 trounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6" id="username" type="text" placeholder="Email" disabled></input>
                        </div> */}

              <div className="content-center mt-3 flex flex-row space-x-4 text-sm">
                <input
                  value={selectedPhone}
                  maxLength={20}
                  onChange={phoneHandler}
                  className="shadow appearance-none border rounded w-full py-1 px-1 trounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-teal-600 focus:border-teal-500 sm:max-w-xs sm:text-sm sm:leading-6"
                  id="username"
                  type="text"
                  placeholder="Phone number"
                ></input>
              </div>

              <div className="content-center h=30 flex flex-row space-x-4 text-sm"></div>

              <div className="content-center mt-1 flex flex-row space-x-4 text-sm">
                {/* <div className='text-right text-xs'>{count}/250</div> */}
                <textarea
                  maxLength="250"
                  value={selectedMemo}
                  onChange={(e) => memoHandler(e)}
                  id="message"
                  rows="4"
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border-2 border-teal-600 focus:ring-teal-600 focus:border-teal-500"
                  placeholder="추가정보를 입력하세요."
                ></textarea>
              </div>
              <div className="text-right text-xs">{count}/250</div>

              <div className="block sm:hidden fixed bottom-10 bg-white">
                <div className="container">
                  {
                    (
                      selectedFirstName &&
                      selectedLastName &&
                      selectedEmail &&
                      selectedPhone
                    ) ? (
                      <Link to="/review">
                        <Button
                          variant="gradient"
                          size="lg"
                          className="w-[21rem] px-3 py-3"
                          color="teal"
                        >
                          NEXT
                        </Button>
                      </Link>
                    ) : (
                      <Button
                        disabled
                        variant="gradient"
                        size="lg"
                        className="w-[21rem] jpx-3 py-3"
                        color="teal"
                      >
                        NEXT
                      </Button>
                    )}
                </div>
              </div>
            </div>
          </div>
          <div className="w-96">
            <div className="hidden md:block mt-10 w-96 max-h-full overflow-y-none bg-white border border-gray-200 rounded-lg shadow sm:p-6">
              <h5 className="mb-3 text-base font-semibold text-gray-900 md:text-xl text-center">
                Services
              </h5>

              <div>No.1 Jimmy Auto</div>
              <div className="text-gray-500 text-sm mb-8">
              {branch?.address} {branch?.postalCode}
              </div>

              <div className="flex flex-row space-x-4 my-5">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                    />
                  </svg>
                </div>
                <div className="text-sm text-gray-500 truncate">
                  {localStorage.getItem("bookingtime").split(" ")[0]}
                </div>
              </div>
              <div className="flex flex-row space-x-4 my-5">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-5 h-5"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>
                </div>
                <div className="text-sm text-gray-500 truncate">
                  {localStorage.getItem("bookingtime").split(" ")[1]}
                </div>
              </div>

              <div className="flex flex-row space-x-4 my-5">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M8.25 18.75a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 0 1-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 0 0-3.213-9.193 2.056 2.056 0 0 0-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 0 0-10.026 0 1.106 1.106 0 0 0-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12"
                    />
                  </svg>
                </div>
                <div className="text-sm text-gray-500 truncate">
                  {localStorage.getItem("make")} {localStorage.getItem("model")}{" "}
                  {localStorage.getItem("year")}
                </div>
              </div>

              {serviceList?.map((itemt, index) => (
                <>
                  <div className="flex flex-row space-x-4 my-5">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M21.75 6.75a4.5 4.5 0 0 1-4.884 4.484c-1.076-.091-2.264.071-2.95.904l-7.152 8.684a2.548 2.548 0 1 1-3.586-3.586l8.684-7.152c.833-.686.995-1.874.904-2.95a4.5 4.5 0 0 1 6.336-4.486l-3.276 3.276a3.004 3.004 0 0 0 2.25 2.25l3.276-3.276c.256.565.398 1.192.398 1.852Z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M4.867 19.125h.008v.008h-.008v-.008Z"
                        />
                      </svg>
                    </div>
                    <div className="text-sm text-gray-500 truncate">
                      {itemt.name}
                    </div>
                  </div>
                </>
              ))}
              {/* <div className="relative "> */}
              <div className="mt-24 hidden md:block">
                {selectedFirstName &&
                  selectedLastName &&
                  selectedEmail &&
                  selectedPhone ? (
                  <Link to="/review">
                    <Button
                      variant="gradient"
                      size="lg"
                      className="w-[21rem] px-3 py-3"
                      color="teal"
                    >
                      NEXT
                    </Button>
                  </Link>
                ) : (
                  <Button
                    disabled
                    variant="gradient"
                    size="lg"
                    className="w-[21rem] jpx-3 py-3"
                    color="teal"
                  >
                    NEXT
                  </Button>
                )}
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Contact;
